//
// Extend from bootstrap
//

// Card

.card {
  border: 0rem;
  box-shadow: $box-shadow-sm;
}

// Img left rounded for card

.img-left-rounded {
  border-radius: 0.5rem 0 0 0.5rem;
  @media (max-width: 768px) {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    border-radius: 0.5rem 0.5rem 0 0;
  }
}

// Card header height

.card-header-height {
  height: 3.75rem;
}

// Card hover

.card-hover {
  transition: box-shadow 0.25s ease;
  &:hover,
  &:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}

// Accordion

.accordion {
  > .card {
    > .card-header {
      margin-bottom: 0rem;
    }
  }
}




// card img size

.card-img-size-600{
  width: 100%;
  @media (min-width: 990px) {
      width: 600px;

  }
}


// card bordered


.card-bordered{
  border: 1px solid $gray-200;
  box-shadow: none;

}


.card-dashed-hover{
  border-style: dashed !important;
  transition: .5s ease;
  &:hover{
    border: 2px dashed $primary !important;

  }
}