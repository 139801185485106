.hide {
  display: none;
  transition: ease 0.4s;
}
.show {
  display: block;
  transition: ease 0.4s;
}

.captchaError {
  border: solid;
  border-color: red;
}

.validationError {
  color: #e53f3c;
}

.nladj {
  font-size: 1.3rem;
  font-weight: 600;
  color: $dark;
  &:hover {
    color: $primary;
  }
}

.card {
  background-color: #ffffff;
}

.modal-content {
  background-color: #0000006b;
}

.modal-header,
.modal-body,
.modal-footer {
  background-color: #ffffff;
}

.form-select:focus,
.form-control:focus {
  border-color: $primary;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $primary;
}

.custom-padding {
  padding: -10px;
}

a:focus {
  outline-color: $primary;
}

.fading-image {
  width: 100%;
  display: block;
  mask-image: linear-gradient(
      to left,
      transparent,
      white 100%,
      white 90%,
      transparent
    ),
    linear-gradient(to right, transparent, white 10%, white 90%, transparent);
}

.lightened-image {
  width: 100%;
  display: block;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}

.hero-txt {
  font-weight: 400;
  font-style: normal;
}

@media screen and (min-width: 1537px) {
  .hero-txt {
    font-size: 4rem;
    max-width: 950px;
    padding-top: 175px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1536px) {
  .hero-txt {
    font-size: 4rem;
    max-width: 950px;
    padding-top: 125px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 991px) {
  .hero-txt {
    font-size: 3rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .hero-txt {
    font-size: 2.5rem;
    max-width: 310px;
    padding-top: 75px;
    margin-left: auto;
    margin-right: auto;
  }
}

.hero-lead {
  font-weight: 400;
  font-style: normal;
}

@media screen and (min-width: 1535px) {
  .hero-lead {
    font-size: 1.75rem;
    max-width: 950px;
    padding-bottom: 175px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1536px) {
  .hero-lead {
    font-size: 1.75rem;
    max-width: 950px;
    padding-bottom: 125px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 991px) {
  .hero-lead {
    font-size: 1.5rem;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .hero-lead {
    font-size: 1.35rem;
    padding-bottom: 75px;
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
  }
}
