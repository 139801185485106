//
// Extended from bootstrap
//

// Background

.bg-trans {
  background-color: #0000008a;
  backdrop-filter: blur(5px);
}

// bg cover

.bg-cover {
  background: no-repeat center center / cover;
}

// bg grayscale

.bg-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

// bg auto

.bg-auto {
  @media (max-width: 1200px) {
    background-size: auto !important;
  }
}

// dot pattern

.dot-pattern {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(../../../../assets/images/pattern/dots-pattern.svg)
      no-repeat;
    top: 0;
    right: 0;
  }
}

.moving-gradient-bg {
  background: linear-gradient(-45deg, #4901b563, #a5660062, #00a2a585);
  background-size: 200% 200%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
